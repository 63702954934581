import { ServiceFactory } from '@/services/ServiceFactory'
const _modulesService = ServiceFactory.get('ModulesService')
// const _billingPlansService = ServiceFactory.get('BillingPlansService')
const _businessTypesService = ServiceFactory.get('BusinessTypesService')
const _barsService = ServiceFactory.get('BarsService')
const _citiesService = ServiceFactory.get('CitiesService')
const _zonesService = ServiceFactory.get('ZonesService')
export default {
  name: 'ZbCompaniesFormComponent',
  data () {
    return {
      postData: {
        manager: {
          email: '',
          name: '',
          cellphone: null,
          countryCode: '591',
          timezoneOffset: 240
          // birthDate: ''
        },
        bar: {
          name: '',
          address: '',
          slogan: '',
          activatedAt: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          cityId: '',
          zbBusinessTypeId: '',
          zone: '',
          info: '',
          services: [],
          paymentMethods: [],
          maxBookingsPerSchedule: null,
          maxPeoplePerBooking: null,
          businessTier: {
            name: '',
            price: null
          },
          telephone: 0,
          nit: ''
        }
      },
      cities: [],
      zones: [],
      billingPlan: [],
      businessType: [],
      services: [ 'Pet friendly', 'WiFi' ],
      payment: [ 'Pago por QR', 'Tarjeta débito/crédito VISA' ],
      modules: [],
      menu: false,
      menu2: false,
      menu3: false,
      activePicker: null,
      loading: false,
      disabled: false,
      barData: [],
      loader: false,
      dateRange: [],
      tiers: [
        { name: 'Categoría especial - 2 Bs.', price: 2 },
        { name: 'Categoría 1 - 3 Bs.', price: 3 },
        { name: 'Categoría 2 - 5 Bs.', price: 5 }
      ]
      // selectTierVal: null
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    menu2 (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    }
  },
  mounted () {
    this.indexCities()
    this.indexZones()
    this.indexBusinessType()
    this.indexModules()
    // this.initialize()
  },
  methods: {
    // async initialize () {
    //   if (this.$route.params.id) {
    //     // console.log(this.$route.params.id)
    //     try {
    //       this.barData = await _barsService.show(this.$route.params.id)
    //       // this.postData.bar = this.barData.bar
    //       this.postData.bar.name = this.barData.bar.name
    //       this.postData.bar.address = this.barData.bar.address
    //       this.postData.bar.slogan = this.barData.bar.slogan
    //       // this.postData.bar.activatedAt = this.barData.bar.activatedAt // quitar la hora o modificar el formulario para que acepte la hora
    //       this.postData.bar.city = this.barData.bar.city
    //       // this.postData.bar.musicTicketPrice = this.barData.bar.musicTicketPrice // no existe el campo musicTicketPrice
    //       this.postData.bar.genres = this.barData.bar.genres
    //       // this.postData.bar.zbBillingPlanId = this.barData.bar.zbBillingPlanId // no existe el campo zbBillingPlanId
    //       // this.postData.bar.zbBusinessTypeId = this.barData.bar.zbBusinessTypeId // el formulario guarda la id, el servicio retorna una cadena de texto
    //       // this.postData.bar.zbModuleIds = this.barData.bar.zbModuleIds // el sercivio devuelce el valor de los modulos de otra forma
    //       // this.loadingForm = false
    //       // console.log(this.barData)
    //     } catch (error) {
    //       // console.info(error)
    //     }
    //   } else {
    //     // console.log('sin parametros')
    //     // this.loadingForm = false
    //   }
    // },
    selectEvent (ev) {
      console.log(ev)
      this.tiers.forEach(t => {
        if (t.price === ev) {
          console.log(t)
          this.postData.bar.businessTier.name = t.name
        }
      })
    },
    async indexCities () {
      try {
        this.cities = await _citiesService.index('Bolivia')
        // console.log(this.cities)
      } catch (error) {
        // console.info(error)
      }
    },
    async indexZones () {
      try {
        this.zones = await _zonesService.index()
        console.log(this.zones)
      } catch (error) {
        // console.info(error)
      }
    },
    async indexBusinessType () {
      try {
        this.businessType = await _businessTypesService.index()
        // console.log(this.businessType.businessTypes[0])
      } catch (error) {
        // console.info(error)
      }
    },
    async indexModules () {
      try {
        this.modules = await _modulesService.index()
        // console.log(this.modules.modules[0])
      } catch (error) {
        // console.info(error)
      }
    },
    async submitData () {
      this.loading = true
      this.disabled = true
      this.postData.manager.cellphone = parseInt(this.postData.manager.cellphone)
      this.postData.bar.maxBookingsPerSchedule = parseInt(this.postData.bar.maxBookingsPerSchedule)
      this.postData.bar.maxPeoplePerBooking = parseInt(this.postData.bar.maxPeoplePerBooking)
      this.postData.bar.telephone = parseInt(this.postData.bar.telephone)
      // this.postData.bar.nit = parseInt(this.postData.bar.nit)

      console.log(this.postData)
      try {
        await _barsService.create(this.postData)
        this.$notify({
          type: 'success',
          text: 'Datos registrados correctamente'
        })
        this.loading = false
        this.$router.push({ name: 'companies' })
        // console.log(this.modules.modules[0])
      } catch (error) {
        this.$notify({
          type: 'error',
          text: error.msg
        })
        this.loading = false
        this.disabled = false
        // console.info(error)
      }
    },
    save (date) {
      this.$refs.menu.save(date)
    },
    save2 (date) {
      this.$refs.menu2.save(date)
    },
    save3 (date) {
      this.$refs.menu3.save(date)
      // this.postData.bar.closingDays.startDate = date[0]
      // this.postData.bar.closingDays.endDate = date[1]
      // console.log(date, this.dateRange)
    },
    close () {
      this.$router.push({ name: 'companies' })
    }
    // async indexModules (){}
  }
}
